.headerDiv {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover;
  border-radius: 50px;
  font-size: 10px;
  overflow: hidden;
}

.headerProfileimage {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover;
  border-radius: 50px;
}

.gx-avatar {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  height: 38px;
  width: 38px;
  position: relative;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
}
