.ant-menu-item-selected {
  background: #fff !important;
}
.ant-menu-title-content {
  padding: 10px 13px;
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 44px;
}
.ant-menu-item-selected .ant-menu-title-content {
  background: #2c4089;
  border-radius: 5px;
  color: #fff;
}
.ant-menu-item-selected .ant-menu-title-content a {
  color: #fff;
}
.sidebar {
  height: 80vh !important;
}
.select-all {
  border: 1px solid #fff;
  padding: 0 !important;
  border-radius: 0.2rem;
}
.top .ant-col-1 {
  padding-left: 0px;
  padding-right: 16px;
  float: none !important;
}
