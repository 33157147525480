.form-control {
  -webkit-transition: none;
  transition: none;
  width: 32px;
  height: 32px;
  text-align: center;
}

.form-control:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #884377;
  outline: 0;
}

.form-control.form-control-solid {
  background-color: #f5f5f5;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #3f4254;
  transition: all 0.3s;
}

.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  background-color: #f5f5f5;
  box-shadow: none;
  border-color: #038fde;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
